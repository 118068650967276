import React from 'react'
import { attendanceSectionThreeContent } from '../../data/attendanceContent'

export const AttendanceSectionThree = () => {
  return (
      <section className='xl:my-10'>
          <h1 className='font-[700] w-11/12 lg:w-3/4 lg:text-center mx-auto text-[35px]'>{attendanceSectionThreeContent.title.normal}<span className='text-[#985CF1]'>{attendanceSectionThreeContent.title.coloured}</span>{attendanceSectionThreeContent.title.normalContinuation}</h1>
          <p className='w-11/12 lg:w-3/4 lg:text-center text-[18px] leading-[35px] mx-auto my-6 md:my-10'>{attendanceSectionThreeContent.paraLine}</p>
          <div className='flex flex-col md:flex-row w-8/12 md:w-11/12 xl:w-10/12 mx-auto 2xl:space-x-5'>
              {
                  attendanceSectionThreeContent.data.map((data, index) => {
                      return (
                          <div key={index} className="flex flex-col items-center my-6">
                              <img src={data.icon} alt="icon" />
                              <p className='text-center text-[18px] py-6'>{data.text}</p>
                          </div>
                      )
                  })
              }
          </div>
    </section>
  )
}
