import React from "react";
import { AttendancePage } from "../components/Attendance/AttendancePage";
import { Layout } from "../components/Layout";
import "../components/index.css";

const Attendance = () => {
    return (
        <Layout >
            <AttendancePage />
        </Layout>
    )
}

export default Attendance;