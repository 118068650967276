import React from 'react'
import { attendanceSectionFourContent } from '../../data/attendanceContent'
import { FeatureBox } from '../FeatureBox'

export const AttendanceSectionFour = () => {
  return (
      <section className='w-11/12 mx-auto'>
          {
              attendanceSectionFourContent.map((data, index) => {
                  return <FeatureBox key={index} {...data} />
              })
          }
    </section>
  )
}
