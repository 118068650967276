import React from 'react'
import { attendanceSectionTwoContent } from '../../data/attendanceContent'

export const AttendanceSectionTwo = () => {
  return (
      <section className='flex flex-col lg:flex-row space-y-5 lg:space-y-0 lg:space-x-5 py-10 w-11/12 mx-auto'>
          <div className='h-auto relative '>
            <div className='absolute gradient z-[-1] h-[100%] w-[100%] rounded-[25px]'></div>
            <div className='p-8 lg:p-5 2xl:p-14 '>
                  <h5 className='text-[30px] xl:text-[35px] font-[700]'>{attendanceSectionTwoContent.firstBox.title.normal}<span className='text-[#985CF1]'>{attendanceSectionTwoContent.firstBox.title.coloured}</span></h5>
                  <p className='mt-5 text-[18px] font-[400] leading-[30px]'>{attendanceSectionTwoContent.firstBox.paraLine}</p>
            </div>
          </div>

          <div className='h-auto relative '>
              <div className='absolute gradient z-[-1] h-[100%] w-[100%] rounded-[25px]'></div>
                <div className='p-8 lg:p-5 2xl:p-14'>
                    <h5 className='text-[30px] xl:text-[35px] font-[700]'>{attendanceSectionTwoContent.secondBox.title.coloured}<span className='text-[#985CF1]'>{attendanceSectionTwoContent.secondBox.title.normal}</span></h5>

                    <p className='mt-5 text-[18px] font-[400] leading-[30px]'>{attendanceSectionTwoContent.secondBox.paraLine}</p>
                </div>
        </div>
    </section>
  )
}
