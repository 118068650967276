import React from 'react'
import { useModal } from '../ContactForm'
import { FooterContactBox } from '../FooterContactBox'
import { AttendanceBanner } from './AttendanceBanner'
import { AttendanceSectionFour } from './AttendanceSectionFour'
import { AttendanceSectionThree } from './AttendanceSectionThree'
import { AttendanceSectionTwo } from './AttendanceSectionTwo'

export const AttendancePage = () => {
  const { modalAction } = useModal();
  return (
      <div>
          <AttendanceBanner />
          <AttendanceSectionTwo />
          <AttendanceSectionThree />
          <AttendanceSectionFour />
          <FooterContactBox>
              <div className='p-4 lg:p-0 lg:w-2/3'>
                <h5 className='font-[700] text-[30px] lg:text-[38px]'>Need assistance with attendance management?</h5>
                <p className='font-[400] text-[18px] py-3'>Prism software is a great tool for your busy schedule.</p>
                <button className='bg-[#985CF1] text-white py-[11px] px-[25px] rounded-[48px] mt-5' onClick={modalAction}>Contact Today</button>

           </div>
          </FooterContactBox>
    </div>
  )
}
